import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://api.envato.debaly.com',
});

// instance.interceptors.request.use(
//   async (config) => {
//     const token = await localStorage.getItem('token');
//     if (token) {
//       // config.cookies.token = token;
//       config.headers.token = token;
//     }
//     return config;
//   },
//   (err) => {
//     return Promise.reject(err);
//   },
// );
export default instance;
