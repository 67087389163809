import React, { useState, useEffect ,Suspense} from 'react';
import { Link as RouterLink, withRouter,browserHistory } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  Link,
  Typography,
} from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Moment from 'moment';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  table: {
    minWidth: 700
  },
  quoteContainer: {
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    display: 'flex',
    marginRight: '5%',
    marginLeft: '5%',
    alignItems: 'top',
    marginTop: 50,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    flexBasis: 700,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  detail: {
    fontWeight: 'bold',
    color: '#4a148c'
  },
  dataCard: {
    // width:200,
    margin: 20
  }
}));

const GetProducts = props => {
  const { history, row } = props;

  const classes = useStyles();

  useEffect( () => {
    (async ()=>{
      await axios({
        method:'get',
        url:'https://api.envato.debaly.com/application'
      }).then(response => {
        setProduct(response.data);
      });

      const AuthStr = await 'Bearer '.concat(localStorage.getItem('access_token'));
      await axios.get('https://api.envato.com/v3/market/buyer/list-purchases', { headers: { Authorization: AuthStr } })
        .then(async response => {
          // If request is good...
          await setItemData(response.data.results);

          // await setTest([response.data.results,response.data.results.items]);

        })
        .catch((error) => {
          console.log('error ' + error);
        });
    })();
  }, []);

  //use Effect End

  const [itemdata, setItemData] = useState([]);

  const [open, setOpen] = useState(true);
  const [product,setProduct] =useState(['']);

  const [editState, setEditState] = useState(true);

  // const filterItemData = itemdata.filter((item) =>{
  //
  //   return(item.item.id == 45644 ||item.item.id == 454545 )})
  // // return product.envatoId.includes(item.item.id) })
  // // return(item.item.id.includes(product.envatoId) )})



  const filterItemData = itemdata.filter((list) => {
    return ( product.some(items=>items.envatoId == list.item.id) )
  } );

  // const filterData3 = product.filter((list) => {
  //   return ( itemdata(item=>item.id === list.envatoId) )
  // } )

  // console.log(itemdata.item)
  return (
    <div className={classes.root}>
      <Suspense fallback={<LinearProgress />}>
        <Grid style={{margin:30}}>
          <div
            align="center"
            style={{margin:30}}
          >

            <Typography
              component="h3"
              variant="h3"
            >
          Your Purchase List From Debaly
            </Typography>
          </div>
          <div>

            <Grid
              container
              spacing={3}
            >
              {filterItemData.map((data) =>
                <Grid
                  item
                  lg={6}
                  md={6}
                  xs={12}
                >
                  <Paper
                    className={classes.paper}
                    style={{padding:20}}
                  >
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                    >
                      {data.item.name}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="body2"
                    >
                      {data.license}
                    </Typography>
                    <
                      Typography
                      gutterBottom
                      variant="body2"
                    >
                  Purchase Date : {Moment(Date(data.sold_at)).format('LL')}
                    </Typography>
                    <
                      Typography
                      gutterBottom
                      variant="body2"
                    >
                    Purchase Code : {data.code}
                    </Typography>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to={'/debaly-products/' +data.code}
                      variant="h6"
                    >
                      <Button
                        color="primary"
                        // onClick={() => history.push('/debaly-products/'+data.code)}
                        variant="outlined"
                      >
                      Configure Mac Address
                      </Button>
                    </Link>

                  </Paper>
                </Grid>

              )}
            </Grid>
          </div>
        </Grid>
      </Suspense>
    </div>
  );
};


export default GetProducts;
