import React, { useState, useEffect, Suspense } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  TextField,
  Typography,
  Container,
  LinearProgress
} from '@material-ui/core';
import SuccessSnackbar from './SuccessSnackbar';
import ErrorSnackbar from './ErrorSnackbar'
// import queryString from 'query-string';
import axios from 'axios';
import useRouter from '../../Routes/useRouter';
import queryString, { stringify } from 'query-string';
import { useForm } from 'react-hook-form';
import {CopyToClipboard} from 'react-copy-to-clipboard';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  table: {
    minWidth: 700
  },
  quoteContainer: {
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    display: 'flex',
    marginTop: 10,
    marginRight: '5%',
    marginLeft: '5%',
    alignItems: 'top',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    flexBasis: 700,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  detail: {
    fontWeight: 'bold',
    color: '#4a148c'
  },
  dataCard: {
    // width:200,
    margin: 20
  }
}));

const MacDetails = props => {

  const [data,setData] =useState(['']);
  const [mac1,setMac1] =useState('');
  const [mac2,setMac2] =useState('');
  const [mac3,setMac3] =useState('');
  const [token,setToken] =useState('');
  const [copy,setCopy] =useState(false);
  const [purchase,setPurchase] =useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [loader,serLoader]=useState(true);



  const classes = useStyles();
  const code =localStorage.getItem('code');
  const router = useRouter();

  useEffect( () => {
    (async () => {
      const accesToken = await localStorage.getItem('access_token')
      await setPurchase(router.match.params.id);
      await axios({
        method: 'get',
        url: 'https://api.envato.debaly.com/purchase',
        params: {
          accessToken: accesToken,
          purchaseCode: router.match.params.id
        }
      })

        .then(function(response) {
          setMac1(response.data.mac[0]);
          setMac2(response.data.mac[1]);
          setMac3(response.data.mac[2]);
          setToken(response.data.token)

        })
        .catch(function(error) {
          console.log(error);
        });

    })();

  }, []);
  //use Effect End



  const { register, handleSubmit, watch, errors } = useForm();

  const onSubmit = data => {

    axios({
      method: 'post',
      url: 'https://api.envato.debaly.com/purchase',
      params: {
        accessToken: localStorage.getItem('access_token'),
        purchaseCode: purchase,
      },
      data:
         [mac1==null?'':mac1,mac2==null?'':mac2,mac3==null?'':mac3],

      headers: {
        'content-type': 'application/json'
      }
    })

      .then(function(response) {
        setOpenSnackbar(true);

      })
      .catch(function(error) {
        setOpenErrorSnackbar(true)
      });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleErrorSnackbarClose = () => {
    setOpenErrorSnackbar(false);
  };





  return (

    <div className={classes.root}>
      <Suspense fallback={<LinearProgress />}>
        <Grid style={{margin:30}}>
          <div
            align="center"
            style={{margin:30}}
          >

            <Typography
              component="h3"
              variant="h3"
            >
            Mac Address Info
            </Typography>
          </div>
          <div>

            <Grid
              container
              lg={12}
              spacing={3}
            >
              <Container>
                <div className={classes.contentBody}>
                  <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="h4"
                    >
                    Please Input MAC address
                    </Typography>
                    <div style={{margin:'10px 0'}}>
                      <input
                        className="form-group"
                        fullWidth
                        id="standard-helperText"
                        label="Mac Addres 1"
                        name="macAddress1"
                        onChange={(e) => {
                          setMac1( e.target.value);}}
                        placeholder="Mac Address 1"
                        style={{width:'100%',height:'40px'}}
                        type="text"
                        value={mac1}
                        variant="outlined"
                      />
                    </div>
                    <div style={{margin:'10px 0'}}>
                      <input
                        className="form-group"
                        fullWidth
                        id="standard-helperText"
                        label="Mac Addres 2"
                        name="macAddress2"
                        onChange={(e) => {
                          setMac2( e.target.value);}}
                        placeholder="Mac Address 2"
                        style={{width:'100%',height:'40px'}}
                        type="text"
                        value={mac2}
                        variant="outlined"
                      />
                    </div>
                    <div style={{margin:'10px 0'}}>
                      <input
                        className="form-group"
                        fullWidth
                        id="standard-helperText"
                        label="Mac Addres 3"
                        name="macAddress3"
                        onChange={(e) => {
                          setMac3( e.target.value);}}
                        placeholder="Mac Address 3"
                        style={{width:'100%',height:'40px'}}
                        type="text"
                        value={mac3}
                        variant="outlined"
                      />
                    </div>
                    <div  style={{marginTop:20}}>

                      <Button
                        className={classes.signUpButton}
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                    SUBMIT
                      </Button>

                    </div>
                    <div style={{backgroundColor:'#aed581',marginTop:15,padding:5,paddingLeft:20,borderRadius:5}}>

                      <CopyToClipboard
                        onCopy={() => setCopy(true)}
                        text={token}
                      >
                        <span>Your Token: <b>{token}</b></span>
                      </CopyToClipboard>

                      <CopyToClipboard
                        onCopy={() => setCopy(true)}
                        text={token}
                      >
                        <Button
                          color="primary"
                          size="small"
                          style={{marginLeft:10, backgroundColor: 'green',
                            '&:hover': {
                              backgroundColor: 'red',
                            },}}
                          variant="contained"
                        >Copy</Button>
                      </CopyToClipboard>

                      {copy ? <span style={{color: 'red',marginLeft:10}}>Copied.</span> : null}
                    </div>
                  </form>
                </div>
              </Container>
            </Grid>
          </div>
        </Grid>
        <SuccessSnackbar
          onClose={handleSnackbarClose}
          open={openSnackbar}
        />
        <ErrorSnackbar
          onClose={handleErrorSnackbarClose}
          open={openErrorSnackbar}
        />
      </Suspense>
    </div>
  );
};


export default MacDetails;
