import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from '../components';
import { Main as MainLayout, Minimal as MinimalLayout } from '../layouts/index';

import {
  NotFound as NotFoundView
} from '../views';

import VerifyEnvato from '../views/verifyEnvato/VerifyEnvato';
import GetKey from '../views/getKey/GetKey';
import GetProducts from '../views/getProducts/GetProductsDetail';
import MacDetails from '../views/MacDetail/MacDetail';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/auth"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={VerifyEnvato}
        exact
        layout={MinimalLayout}
        path="/auth"
      />
      <RouteWithLayout
        component={GetKey}
        // exact
        layout={MinimalLayout}
        path="/automatic-redirect"
      />

      <RouteWithLayout
        component={MacDetails}
        // exact
        layout={MinimalLayout}
        path="/debaly-products/:id"
      />
      <RouteWithLayout
        component={GetProducts}
        // exact
        layout={MinimalLayout}
        path="/debaly-products"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
