import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter,BrowserRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button,
  IconButton,
  TextField,
  Divider,
  Link,
  Typography
} from '@material-ui/core';
import axios from 'axios';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%',
  },
  quoteContainer: {
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  card: {
    width: theme.breakpoints.values.md,
    padding:'40px',
    alignItems:'center',
    // margin:'20px',
    maxWidth: '100%',
    overflow: 'unset',
    height:'70%',
    display: 'flex',
    // position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '100%'
    }
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    alignSelf:'center',
    display: 'flex',
    marginTop: 40,
    maxWidth: '80%',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    flexBasis: 700,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const VerifyEnvato = props => {
  const { history } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));

  }, [formState.values]);


  // KD7cRahuEhWlkoUGDVKVekkDbfdktM9b
  const handleEnvato = async event => {
    await event.preventDefault();
    window.location.href = await 'https://api.envato.com/authorization?response_' +
        'type=code&client_id=debaly-envato-verify-dr43vbss&redirect_uri=https:' +
        '//verify.debaly.com/automatic-redirect';

    // setTimeout(async ()=> {
    await history.push('/automatic-redirect');
    //
    // }, 10000);
    // await console.log('ss');
  };


  // const getData = async () => {
  //   await handleEnvato;
  //   await console.log('kasds');
  // };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >

        <Grid
          className={classes.content}
          item
          lg={12}
          xs={12}
        >
          <div className={classes.content}>

            <div className={classes.contentBody}>
              <Card className={classes.card}>
                <CardContent className={classes.content}>
                  {/*<LockIcon className={classes.icon}/>*/}
                  {/*<Typography*/}
                  {/*  gutterBottom*/}
                  {/*  variant="h3"*/}
                  {/*>*/}
                  {/*  Sign in*/}
                  {/*</Typography>*/}
                  {/*<Typography variant="subtitle2">*/}
                  {/*  Sign in on the internal platform*/}
                  {/*</Typography>*/}
                  {/*<LoginForm className={classes.loginForm}/>*/}
                  {/*<Divider className={classes.divider}/>*/}
                  <CardMedia
                    className={classes.media}
                    image="/images/auth.png"
                    title="Cover"
                  >
                    <Typography
                      color="inherit"
                      variant="subtitle1"
                    >
                      Welcome To Debaly Token Management System
                    </Typography>
                  </CardMedia>
                </CardContent>

              </Card>
              <Card
                className={classes.card}
                style={{alignItems:'center'}}
              >
                <div >

                  <Typography
                    align="center"
                    className={classes.title}
                    variant="h3"
                  >
                Welcome To Debaly Envato Verfication
                  </Typography>
                  <Typography
                    align="center"
                    color="textSecondary"
                    gutterBottom
                    style={{marginTop:30}}
                  >
                    You need to Sign In with Envato
                  </Typography>
                  <Typography
                    align="center"
                    color="textSecondary"
                    gutterBottom
                    style={{marginTop:20}}
                  >
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      // fullWidth
                      onClick={handleEnvato}
                      size="large"

                      // onClick={() => {
                      //   window.location.href = 'https://api.envato.com/authorization?response_type=code&client_id=debaly-app-verify-xrkkaxrl&redirect_uri=http://localhost:3002/verify';
                      // }}
                      type="submit"
                      variant="contained"
                    >
                      Sign in now
                    </Button>
                  </Typography>

                  <Grid
                    container
                    // spacing={2}
                  />

                  <Grid
                    className={classes.contentBody}
                    style={{alignItems:'center'}}
                  />
                  <Grid
                    className={classes.contentBody}
                  />

                </div>
              </Card>

            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};


export default VerifyEnvato;
