import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography
} from '@material-ui/core';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import axios from 'axios';
import qs from 'qs';


const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop:'200px'
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: '50px',
    },
  },
}));

const GetKey = props => {
  const { history } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  // const getCode=queryString.stringifyUrl();
  window.onload = async () => {
    try {
      const url_string=await (window.location.href).toLowerCase();
      var url =await new URL(url_string);
      await axios({
        method: 'post',
        url: 'https://api.envato.com/token',
        data: qs.stringify({
          code: url.searchParams.get('code'),
          client_secret: 'KD7cRahuEhWlkoUGDVKVekkDbfdktM9b',
          client_id: 'debaly-envato-verify-dr43vbss',
          grant_type: 'authorization_code'
        }),
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      })
        .then(async function (response) {
          await localStorage.setItem('access_token', response.data.access_token);
          await history.push('/debaly-products');
        })
        .catch(function(error) {
          console.log(error);
        });

      // await localStorage.setItem('code',codeenv);
      // await history.push('/debaly-products');
      // return(codeenv);
    }catch (e) {
      console.log(e);
    }
  };



  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignIn = event => {
    event.preventDefault();
    history.push('/');
  };
  const localCode =localStorage.getItem('code');

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.container}>>
      <div className={classes.root}>
        <LinearProgress />
        <LinearProgress color="secondary" />
      </div>
    </div>
  );
};


export default GetKey;
